#fill-defaults-table {
  // width: 100%;

  background: white;
  border: 2px solid #ddd;
}

.fill-defaults-table-header {
  background-color: #ddd;
}

.fill-defaults-table-row {

}

.fill-defaults-table-cell {
  padding: 0.3rem;
}
