#agreement-selection-tab {
  width: 100%;
  height: 8%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  background-color: white;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

#agreement-selection-tab div {
  height: 50px;

  padding: 0 1em;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#agreement-selection-tab p {
  margin: 0;
  padding: 0;
}

#edit-agreements-container {
  width: 100%;
  height: 92%;
}

#edit-agreements-table-container {
  width: 100%;
  height: 100%;
}

.agreement-tab {
  color: black;
  text-decoration: none;
}

.agreement-tab:hover {
  text-decoration: none;
}
