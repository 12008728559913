#fields-control {
  padding: 1rem;
  
  background: white;
  font-size: 13px;
  border: 2px solid #ddd;
  border-radius: 0.3rem;
  
  #fields-control-select-container {
    height: 200px;
    overflow-y: scroll;
  }

  .field-control-select {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.3rem;
    }
  }
}

#fields-control-input {
  margin-bottom: 1rem;
  padding: 0.3rem;
}

.invisible {
  display: none;
}
