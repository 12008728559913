.support-section-row {
  display: flex;
  flex-direction: row;
  width: 100%;

  .support-section-column {
    margin: 1rem;
    flex-grow: 1;
    max-width: 50%;
  }
}

.support-card {
  width: 275px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
  padding: 1rem;

  background-color: white;
  border: 1px solid #ddd;
  border-radius: 1rem;

  .support-card-header {
    font-size: 14pt;
    font-weight: bold;
  }

  .support-card-body {
    margin: 0.5rem 0;
    margin-top: 0;
  }

  .support-card-footer {
    margin: 0.3rem 0;

    a {
      padding: 0.3rem 0.7rem;

      background-color: #d8d4ce;
      border-radius: 5px;
      color: #4b4f54;
      text-decoration: none;

      &:hover {
        background-color: #bfb8af;
      }
    }
  }
}
