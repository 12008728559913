#editor-controls {
  width: 100%;
  height: 10%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 4;

  padding: 1rem;

  background-color: white;
}

#selection-controls {
  display: flex;
  align-items: center;
}

#always-available-controls {
  display: flex;
  align-items: center;
}

#editor-table {
  background-color: white;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-collapse: collapse;
  border: 2px solid #ddd;
  border-radius: 5px;
  color: #3f3f3f;
}

#freeze-table {
  background-color: white;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-collapse: collapse;
  border: 2px solid #ddd;
  border-bottom: 10px solid #ddd;
  border-radius: 5px;
  color: #3f3f3f;
}

#editor-table-container {
  width: 100%;
  height: 90%;

  overflow: scroll;
}

editor-table-container tr {
  border: 1px solid #ddd;

  &:hover {
    background-color: #f3f3f3;
  }
}

editor-table-container td {
  font-size: 10pt;
  border-right: 1px solid #ddd;
}

.record-table-header {
  min-width: 200px;
  font-size: 10pt;

  padding: 0.5rem;

  border: 1px solid #ddd;
  overflow: hidden;
  white-space: nowrap;
  background-color: #f0f0f0;
}

.record-table-header-label {
  width: 100%;

  margin-bottom: 0.3rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.record-table-header-filter {
  display: flex;
  align-items: center;

  input {
    width: 100%;
    border: 2px solid #ddd;
    padding: 0.3rem;
  }
}

.frozen-table-header {
  min-width: 200px;
  font-size: 10pt;

  padding: 0.5rem;

  border: 1px solid #ddd;
  overflow: hidden;
  white-space: nowrap;
  background-color: #d8d8d8;
}

.frozen-table-header-label {
  width: 100%;

  margin-bottom: 0.3rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.frozen-table-header-filter {
  display: flex;
  align-items: center;

  input {
    width: 100%;
    border: 2px solid #ddd;
    padding: 0.3rem;
  }
}

.frozen-selection-header {
  min-width: 50px;
  height: 50px;

  background-color: #d8d8d8;
}

.column-icon {
  margin-right: 0.3rem;
}

.editor-control-button {
  min-width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;
  margin: 0 0.1rem;

  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }

  &:active {
    background-color: #ccc;
  }

  span {
    margin-left: 0.3rem;
  }
}

.selection-header {
  min-width: 50px;
  height: 50px;

  background-color: #f0f0f0;
}

.selection-cell {
  height: 45px;
}

.invalid-row {
  background-color: rgb(248, 214, 214) !important;
}

.deleted-row {
  background-color: rgb(219, 217, 217) !important;
}

.promoted-row {
  background-color: rgb(218, 241, 212) !important;
}
