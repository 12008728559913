.dashboard-image {
  width: 80%;
}

#dashboard-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center;

  overflow: scroll;
}

#dashboard-charts {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.circle-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 2rem 6rem;

  svg {
    margin-bottom: 1.5rem;
  }

  .chart-data {
    font-size: 14pt;
  }

  .chart-label {
    font-weight: bold;
    font-size: 20pt;
  }
}

#dashboard-text {
  font-size: 18pt;
}

.big-number {
  width: 300px;
  height: 300px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 72pt;
  font-weight: bold;
  color: #555;
}
