#login-container-background {
  background-image: url(https://ok2static.oktacdn.com/fs/bco/7/fs0nvu0806a2TtY3R0x7);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;
}

#login-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#login-header {
  width: 100%;

  padding: 1rem;

  text-align: center;
  background-color: white;
}

#login-footer {
  width: 100%;

  display: flex;
  justify-content: space-between;

  background-color: white;
}

#login-footer span {
  margin: 0.5rem 5rem;

  font-size: 10pt;
}

#login-content {
  border: 1px solid #ddd;
  background-color: white;
}

#login-content-logo {
  width: 375px;
  
  padding: 2rem;

  border-bottom: 1px solid #ddd;
}

#login-content-form {
  margin: 2rem;
}

.form-line-item {
  margin: 1em;
}

#sign-in-button {
  width: 100%;
}
