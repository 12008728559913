#upload-history-table {
  margin: 2rem;
  
  font-size: 10pt;
}

.upload-history-table-header {
  min-width: 200px;

  padding: 0.7rem;

  background-color: #f0f0f0;
}

.upload-history-table-row {
  border: 1px solid #ddd;
}

.upload-history-table-row:hover {
  border: 2px solid rgb(175, 238, 238);
}

.upload-history-table-cell {
  padding: 0.5rem;

  background-color: white;
  color: #3f3f3f;
}

.upload-history-table-cell a {
  text-decoration: none;
  color: #3f3f3f;
}

.sort-controls {
  margin-left: 1rem;
}
