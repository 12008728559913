#project-overview-table {
  font-size: 10pt;
}

.project-overview-table-header {
  min-width: 200px;
  background-color: #f0f0f0;
}

.project-overview-table-row {
  border: 1px solid #ddd;
}

.project-overview-table-row:hover {
  border: 2px solid rgb(175, 238, 238);
}

.project-overview-table-cell {
  padding: 0.5rem;

  background-color: white;
  color: #3f3f3f;
}

.project-overview-table-cell a {
  text-decoration: none;
  color: #3f3f3f;
}
