#fill-defaults-table-controls {
  height: 10%;
  
  display: flex;
  align-items: center;

  background-color: white;

  div {
    display: flex;
  }
}
