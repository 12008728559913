.report-table {
  margin: 1rem;

  font-size: 10pt;
}

.report-table-header {
  min-width: 200px;

  padding: 0.7rem;

  background-color: #f0f0f0;

  .report-table-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
