.table-number-container {
  border: 0px solid black;

  i {
    display: none;
  }

  &:hover {
    border: 1px solid black;

    i {
      display: initial;
      margin: 0.3rem;
    }
  }
}

.table-number-input {
  padding: 0.5rem;
  border: 0px solid black;
  background-color: transparent;
}

.invalid {
  border: 1px solid rgb(235, 168, 168);

  i {
    display: initial;
    color: rgb(235, 168, 168);
  }
}
