.aim-nav-link {
  width: 100%;

  display: flex;
  align-items: center;

  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1.5em;

  color: #4b4f54;

  .aim-nav-link-label {
    margin-left: 1rem;

    color: #4b4f54;
  }

  &:hover {
    background-color: #bfb8af;
  }
}
