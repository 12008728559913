#aim-modal-container {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

#aim-modal-content {
  width: 40em;

  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;

  background-color: #fefefe;
  border: 2px solid #ddd;
  border-radius: 1rem;
}

.visible-modal {
  display: initial;
}

.invisible-modal {
  display: none;
}
